<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro" :title="titulo">
      {{ titulo }}
    </v-card-title>
    <master-detail
      has-year-filter
      :formTitle="titulo"
      :can-delete="false"
      :cols="cols"
      :resourceUrl="resourceUrl"
      :opts="opts"
    ></master-detail>
  </v-card>
</template>

<script>
import years from '@/helpers/yearsOptions';

export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    cols: function () {
      return [
        {
          key: "pIncentivadoId",
          name: "Modelo",
          type: this.$fieldTypes.SELECT,
          rel: { to: "modelos", key: "id", name: "modelo" },
          rules: [{ rule: "required" }],
        },
        {
          key: "anoBase",
          name: "Ano Base",
          type: this.$fieldTypes.SELECT,
          rel: { to: "years", key: "value", name: "text" },
          rules: [{ rule: "required" }],
          hideInTable: true,
          colSize: 6,
        },
        {
          key: "aliquota",
          name: "Alíquota",
          type: this.$fieldTypes.DOUBLE,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
      ];
    },
    client: function () {
      return this.getClient();
    },
    codigoProduto: function () {
      return this.$route.params.codigoProduto;
    },
    nomeProduto: function () {
      const { modelos } = this.opts;

      if (modelos.length) {
        return modelos[0].produto;
      }

      return '';
    },
    resourceUrl: function () {
      return `/v1/faturamento/aliquota/${this.client.clientId}/produto/${this.codigoProduto}`;
    },
    resourceModelos: function () {
      return this.apiResource(
        `/v1/faturamento/produtosincentivados/${this.client.clientId}/${this.codigoProduto}`
      );
    },
    titulo: function () {
      return `Alíquotas de ${this.nomeProduto || 'produto'}`;
    },
  },
  created: function () {
    this.resourceModelos.get().then((response) => {
      this.opts.modelos = response;
    });
  },
  data: function () {
    return {
      opts: {
        modelos: [],
        years,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-title-cadastro {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
